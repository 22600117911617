import { lazy } from 'react';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import LeaveIcon from '@mui/icons-material/EventBusy';
import ProjectIcon from '@mui/icons-material/AssignmentTurnedIn';
import EditProject from '@mui/icons-material/EditRoad';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonIcon from '@mui/icons-material/Person';
import UserManagementIcon from '@mui/icons-material/ManageAccounts';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Laptop } from '@mui/icons-material';

const TimeSheet = lazy(() => import('../pages/TimeSheet'));
const TimeSheetLog = lazy(() => import('../pages/TimeSheetLog'));
const Projects = lazy(() => import('../pages/Projects'));
const ManageProjects = lazy(() => import('../pages/ManageProject'));
const ManageAssets = lazy(() => import('../pages/ManageAssets'));
const Assets = lazy(() => import('../pages/Assets'));
const Users = lazy(() => import('../pages/Users'));
const Profile = lazy(() => import('../pages/Profile'));
const LeaveRequests = lazy(() => import('../pages/LeaveRequests'));
const Leaves = lazy(() => import('../pages/Leaves'));
const UserManagement = lazy(() => import('../pages/UserManagement'));
const LeaveSummary = lazy(() => import('../pages/LeaveSummary'));
const Claims = lazy(() => import('../pages/Claims'));
const ManageClaims = lazy(() => import('../pages/ManageClaims'));
const ClaimRequest = lazy(() => import('../pages/ClaimRequest'));

export const ParentMenus = {
  leaveDetails: 'Leave Details',
  userManagement: 'User Management',
};

export const routeConstants = [
  {
    name: 'Time Sheet',
    path: '/time-sheet',
    isMenuPage: true,
    component: TimeSheet,
    icon: PendingActionsIcon,
  },
  {
    name: 'Leaves',
    path: '/leaves',
    isMenuPage: true,
    component: Leaves,
    icon: LeaveIcon,
  },
  {
    name: 'Claims',
    path: '/claims',
    isMenuPage: true,
    component: Claims,
    icon: ReceiptIcon,
  },
  {
    name: 'Time Sheet Log',
    path: '/time-sheet-log',
    isMenuPage: true,
    component: TimeSheetLog,
    icon: WorkHistoryIcon,
  },
  {
    name: 'Leave Requests',
    path: '/leave-requests',
    isMenuPage: true,
    component: LeaveRequests,
    icon: LeaveIcon,
    parent: ParentMenus.leaveDetails,
  },
  {
    name: 'Leave Summary',
    path: '/leave-summary',
    isMenuPage: true,
    component: LeaveSummary,
    icon: SummarizeIcon,
    parent: ParentMenus.leaveDetails,
  },
  {
    name: 'Projects',
    path: '/projects',
    isMenuPage: true,
    component: Projects,
    icon: ProjectIcon,
  },
  {
    name: 'Manage Project',
    path: '/projects/manage-project',
    isMenuPage: false,
    component: ManageProjects,
    icon: EditProject,
  },
  {
    name: 'Users',
    path: '/users',
    isMenuPage: true,
    component: Users,
    icon: PeopleAltIcon,
    parent: ParentMenus.userManagement,
  },
  {
    name: 'Manage Roles',
    path: '/user-management',
    isMenuPage: true,
    component: UserManagement,
    icon: UserManagementIcon,
    parent: ParentMenus.userManagement,
  },
  {
    name: 'Profile',
    path: '/profile',
    isMenuPage: false,
    component: Profile,
    icon: PersonIcon,
  },
  {
    name: 'Profile',
    path: '/profile/:id',
    isMenuPage: false,
    component: Profile,
    icon: PersonIcon,
  },
  {
    name: 'My Assets',
    path: '/my-assets',
    isMenuPage: false,
    component: Assets,
    icon: Laptop,
  },
  {
    name: 'Manage Assets',
    path: '/assets',
    isMenuPage: true,
    component: ManageAssets,
    icon: Laptop,
  },
  {
    name: 'Manage Claims',
    path: '/claims/manage-claims',
    isMenuPage: false,
    component: ManageClaims,
    icon: ReceiptLongIcon,
  },
  {
    name: 'Manage Claims',
    path: '/claim-requests/manage-claims',
    isMenuPage: false,
    component: ManageClaims,
    icon: ReceiptLongIcon,
  },
  {
    name: 'Claim Requests',
    path: '/claim-requests',
    isMenuPage: true,
    component: ClaimRequest,
    icon: ReceiptLongIcon,
  },
];

export const BloodGroups = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'];
